@import "../../../assets/theme/_var.scss";

.container_swapwrap {
  min-height: 870px;
  background: url(../../../assets/images/anchorswap-bg.jpg) top center no-repeat;
  background-size: cover;

  .container_inside {
    padding: 0px;
    padding-top: 70px;
    padding-bottom: 70px;
    max-width: 400px;
    @media (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .container_swapwrap__header {
    border-bottom: 1px solid $secondaryColor;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 36px;
    padding-bottom: 26px;
    margin-bottom: 18px;
    min-height: 120px;
    @media (max-width: 767px) {
      padding-top: 20px;
      padding-bottom: 5px;
      min-height: auto;
    }
    h2 {
      font-family: $secondaryFont;
      font-size: 25px;
      color: $whiteColor;
      margin: 0 0 10px 0;
      @media (max-width: 767px) {
        font-size: 20px;
      }
    }
    p {
      font-family: $baseFont;
      font-size: 13px;
      color: $secondaryColor;
      font-weight: 700;
    }

    &.liquidity_header {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .linkBack {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
      }
    }
    &.liquidity_default_header {
      p {
        margin-bottom: 15px;
      }
      .liquidity_default_header__left {
        flex: 1;
        .btn {
          @media (max-width: 767px) {
            min-width: 118px;
            font-size: 14px;
            height: 40px;
            line-height: 40px;
            margin-bottom: 15px;
          }
        }
      }

      .liquidity_default_header__right {
        flex: 0 0 auto;
        a {
          margin-left: 10px;
        }
      }
    }
  }

  .button_unlockWallet {
    margin-top: 25px;
    margin-bottom: 25px;
    button {
      @media (max-width: 767px) {
        font-size: 13px;
      }
    }
  }

  .btnSwap {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    .btnSwapStyle {
      background: transparent;
      border: 0px;
      padding: 0px;
      cursor: pointer;
      transition: all 0.25s;
      transform: scale(1);

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

.addLiquidity_info_header {
  font-family: $baseFont;
  color: $whiteColor;
  font-weight: 700;
  font-size: 13px;
}
.addLiquidity_info_card {
  background-color: $blackColor;
  border-radius: 25px;
  font-family: $baseFont;
  color: #838383;
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;
  padding: 25px;
  margin-bottom: 15px;
  @media (max-width: 767px) {
    padding: 15px 20px;
  }
}
.addLiquidity_info_instruction {
  font-family: $baseFont;
  color: $whiteColor;
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 25px;
}
.slippage {
  display: flex;
  text-align: center;
  justify-content: space-between;
  margin-top: 15px;
}
.card_style_bottom {
  margin-top: 25px;
  ul {
    margin: 0;
    padding: 20px;
    li {
      margin: 0;
      padding: 0 0 5px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.pr-val {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding: 0 10px;
}
