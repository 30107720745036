.importpoolbox{
    border: 1px solid #E3D42E;
    border-radius: 20px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 17px;
    }
    p{
        font-size: 13px;
        font-weight: 600;
        margin-left: 10px;
    }
}
.importpooldetails{
    padding: 50px 0 0 0;
    p{
        font-size: 13px;
        line-height: 22px;
        color: #FFFFFF;
        font-weight: 700;
        text-align: center;
    }
    h4{
        font-size: 15px;
        line-height: 19px;
        color: #E3D42D;
        font-weight: 700;
    }
    ul{
        margin: 0 0 40px 0;
        padding: 0;
        li{
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 13px;
            line-height: 22px;
            color: #FFFFFF;
            font-weight: 700;
            p{
                img{
                    width: 17px;
                }
            }
        }
    }
}
.importpool{
    padding-left: 20px;
    padding-right: 20px;
}
.liquidityList{
    border: 1px solid #496989;
    border-radius: 9px;
    margin: 0 0 10px 0;
    .liquidityListMain{
        display: flex;
        align-items: center;
        padding: 12px 15px;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 22px;
        font-weight: 700;
        position: relative;
        &:before{
            position: absolute;
            right: 15px;
            top: 18px;
            content: "";
            width: 15px;
            height: 15px;
            background: url(../../../assets/images/down-arrow.png);
            background-size: 100%;
        }
        &.openbox{
            &:before{
                transform: rotate(-180deg);
            }
        }
        img{
            padding-right: 4px;
            width: 20px;
        }
    }    
    ul{
         padding-bottom: 10px;
        li{
            display: flex;
            align-items: center;
            padding: 5px 15px;
            justify-content: space-between;
            font-size: 14px;
            line-height: 22px;
            font-weight: 700;
            img{
                width: 20px;
                margin-left: 4px;
            }
            span{
                display: flex;
                align-items: center;
            }
        }
    }    
}
.centerArrow{
    display: flex;
    justify-content: center;
    padding:0 ;
    img{
        width: 20px;
    }
}
.Liquiditybox{
    border: 1px solid #496989;
    border-radius: 9px;
    padding: 20px 15px;
    margin: 10px 30px;
    &.info{
        padding: 25px 15px;
        ul{
            li{
                padding-bottom: 10px;
            }
        }
    }
    ul{
        margin: 0;
        padding: 0;
        li{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 13px;
            line-height: 21px;
            font-weight: 700;
            color: #fff;
            span{
                color: #E3D32D;
                &.white{
                    color: #fff;
                }
            }
            button{
                background: #496989;
                color: #E3D32D;
                border: 0px;
                border-radius: 20px;
                width: 80px;
                display: block;
                padding: 10px 0;
            }
        }
    }
    h3{
        font-size: 20px;
        font-weight: 700;
        margin:8px 0;
    }
    .slidecontainer {
        width: 100%;
        margin-bottom: 20px;
        .slider{
            .value{
                font-size: 20px;
                font-weight: 700;
                margin: 8px 0;
            }
        }
        .rangeslider-horizontal{
            height: 2px !important;
        }
        .rangeslider__fill{
            background-color: #E3D32D ;
        }
        .rangeslider__handle{
            width: 10px;
            height: 10px;
            background: #5c5c5c;
            &::after{
                display: none !important;
                }
            }
      }
        // .slider {
        //     -webkit-appearance: none;
        //     width: 100%;
        //     height: 2px;
        //     background: #E3D32D;
        //     outline: none;
        //     opacity: 0.7;
        //     -webkit-transition: .2s;
        //     transition: opacity .2s;
        //   }
        //   .slider:hover {
        //     opacity: 1;
        //   }
        //   .slider::-webkit-slider-thumb {
        //     -webkit-appearance: none;
        //     appearance: none;
        //     width: 10px;
        //     height: 10px;
        //     background: #5c5c5c;
        //     cursor: pointer;
        //   }
          
        //   .slider::-moz-range-thumb {
        //     width: 10px;
        //     height: 10px;
        //     background: #5c5c5c;
        //     cursor: pointer;
        //   }
   
}
.Liquidityprice{
    margin: 15px 45px;
    padding: 0;
    li{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 13px;
        line-height: 21px;
        font-weight: 700;
        color: #fff;
        span{
            text-align: right;
        }
    }
}
.LiquidityboxInput{
    border: 0px;
    padding: 0;
    margin: 15px 30px;
    .centerArrow{
        margin: 10px 0;
    }
}
.Liquiditybtnbox{
    margin: 15px 30px;
    padding: 0;
    li{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .btn{
            background-color: #fff ;
            border-color: #fff;
            &:hover{
                background-color: #E3D32D ;
                border-color: #E3D32D;
                color: #fff;
            }
        }
        .removeBtn{
            background-color: #E3D32D !important;
            border-color: #E3D32D !important;
            color: #fff !important;
            &:disabled{
                background-color: #496989 !important;
                border-color: #496989 !important;
            }
        }
    }
}
.lp-class{
    margin-top: 20px;
    h4{
        padding-left: 15px;
        color: #E3D32D;
        margin-bottom: 10px;
    }
}
.LptokensList{
    margin: 15px;
    padding: 0;
    li{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 5px;
        img{
            width: 20px;
        }
        span{
            display: flex;
            align-items: center;
        }
    }
}
.lessMargin_bottom{
    .row{
        img{
            width: 20px;
        }
    }
}
.removeModalClass{
    .lessMargin_bottom{
        margin: 20px 0;
    }
    .row{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0px;
        h2{
            margin: 10px 0;
        }
        img{
            margin: 10px 0;
            width: 30px;
            margin-right: 5px;
        }
        span{
            display: flex;
        }
        .plus{
            margin:0;
        }
    }
    ul{
        li{
            span{
                img{
                    width: 20px;
                }
            }
        }
    }
}
.removeCardPoolShare{
    margin:10px 0;
    .importpooldetails{
        margin:25px !important;
        padding:0 !important;
    }
}
