.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.kjGbxC{
  margin: 10px;
}
.lp-class{
  padding:10px;
  .row{
    padding-left: 20px;
    .amt-class{
      float: right;
    }
  }
}
.modal_headerbb{
  border-bottom: 0 !important;
}

.borderClass{
  border: 2px solid rgb(82, 75, 99);
  border-radius: 10px;
  padding: 10px;
 
  .sc-jONnTn{
    display: flex;
    width: 100%;
    justify-items: center !important;
  }
}
.transaction_body{
  text-align: center;
  margin:0;
  min-height: 150px;
  padding:10px;
}

.backdrop{
  background: rgba(0,11,41, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  left:0px;
  top:0px;
  z-index: 3;
}

.borderClassup{
  border: 0px;
  height: auto !important;
  .borderClass{
    border-bottom: 0 !important;
  }
  ul{
    margin: 0 0 30px 0;
    padding: 0;
      li{
        display: flex;
        width: 100%;
      justify-content: space-between !important;
      font-size: 13px;
      font-weight: 600;
      line-height: 22px;
    
      }
  }
}

.Liquidityint{
  display: flex;
  justify-content: space-between;
  li{
    text-align: center;
    font-size: 12px;
  }
}
.transactionsList{
  ul{
      width: 100%;
      li{
      display: flex;
      justify-content: space-between;
    }
  }
}
.tokenList{
  img{
    width: 20px;
  }
}